<template>
  <!-- 修改或新增企业（代理商）-->
  <el-dialog
      v-model="dialogVisible"
      :title="'编辑代理商信息'"
      top="20px"
      height="400px"
      :close-on-click-modal="false"
      :append-to-body="true"
      :show-close="true"
      :before-close="handleClose">
    <div class="dialog-item1">
      <el-form :model="formData"   :label-position="'top'" label-width="100px" :rules="rules" class="el-form-box" ref="ruleFormRef">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="企业名称:" prop="name">
              <el-input v-model="formData.name" placeholder=""/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="24">
            <el-form-item label="联系人" prop="userName" >
              <el-input v-model="formData.userName" placeholder=""/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="24" >
            <el-form-item label="联系电话:" prop="tel">
              <el-input  v-model="formData.tel" type="number" placeholder=""/>
            </el-form-item>
          </el-col>
<!--          <el-col :md="12" :sm="24">-->
<!--            <el-form-item label="登录账号:" prop="account">-->
<!--              <el-input  v-model="formData.account"  placeholder="" autocomplete="off" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :md="12" :sm="24">-->
<!--            <el-form-item label="登录密码:" prop="password">-->
<!--              <el-input  v-model="formData.password" type="password" autocomplete="new-password" placeholder=""/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :md="12" :sm="24">
            <el-form-item label="微信支付商户号（mchid）" prop="mchId">
              <el-input v-model="formData.mchId" placeholder=""/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="24">
            <el-form-item label="微信支付密钥（secret）" prop="secret">
              <el-input v-model="formData.secret" placeholder=""/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="24">
            <el-form-item label="商户key(apiclient_key.pem)" prop="keyFile">
              <template #label>
                商户key(apiclient_key.pem)
                <el-tooltip class="box-item" effect="dark"
                            content="下路径下载：微信商户平台(pay.weixin.qq.com)&ndash;&gt;账户中心&ndash;&gt;账户设置-->API安全"
                            placement="top-start">
                  <el-icon>
                    <InfoFilled/>
                  </el-icon>
                </el-tooltip>
              </template>
              <uploadFile :list="formData.keyFile" @changeData="(event)=>formData.keyFile=event"></uploadFile>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="24">
            <el-form-item label="商户证书(apiclient_cert.pem)" prop="certFile">
              <template #label>
                商户证书(apiclient_cert.pem)
                <el-tooltip class="box-item" effect="dark"
                            content="下路径下载：微信商户平台(pay.weixin.qq.com)&ndash;&gt;账户中心&ndash;&gt;账户设置-->API安全"
                            placement="top-start">
                  <el-icon>
                    <InfoFilled/>
                  </el-icon>
                </el-tooltip>
              </template>
              <uploadFile :list="formData.certFile" @changeData="(event)=>formData.certFile=event"></uploadFile>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="地址:" prop="address">
              <el-input type="textarea" v-model="formData.address" placeholder="请输入地址"/>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="save(ruleFormRef)"
       v-loading="loading" >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import uploadFile from "@/components/uploadFile"
import {ref, defineEmits, reactive, computed,onMounted} from 'vue';
import {ElMessage} from "element-plus";
const emits = defineEmits(['close', 'success'])
const dialogVisible = ref(true)
import {useStore} from 'vuex'
const store = useStore()
const loading = ref(false)
const ruleFormRef = ref()
var formData = reactive({
  name: null,
  userName: null,
  tel: null,
  address: null,
  mchId: null, // 商户号
  secret: null, // 微信支付秘钥
  //apiclient_key_34047ed8-3f6b-4fe5-978d-8299d469bdf8.pem
  keyFile: null, //keyFile
  certFile: null, //certFile
})
const rules = {
  name: [{required: true, message: '请输入企业名称', trigger: ['blur']}],
  userName: [{required: true, message: '请输入联系人姓名', trigger: ['blur']}],
  tel: [{required: true, message: '请输入手机号码', trigger: ['blur']}, {
    pattern: /^1[3456789]\d{9}$/,
    message: '请输入正确的手机号码',
    trigger: ['blur',"change"]
  }],
  mchId: [{required: true, message: '请输入企业商户号', trigger: ['blur']}],
  secret: [{required: true, message: '请输入微信支付秘钥', trigger: ['blur']}],
  keyFile: [{required: true, message: '请上传key', trigger: ['blur',"change"]}],
  certFile: [{required: true, message: '请上传证书', trigger: ['blur',"change"]}],
  address: [{required: true, message: '请输入企业地址', trigger: ['blur']}],
}

const handleClose = () => {
  dialogVisible.value = false
  emits("close", true)
}
// 保存
const save = (ruleFormRef) => {
  if (!ruleFormRef) return
  ruleFormRef.validate((valid, error) => {
    if (valid) {
      loading.value = true
      store.dispatch('loginModel/updateAdminInfo', formData).then(res=>{
        if(res.success){
          ElMessage.success("修改成功")
          emits("success",  {flag:"update"})
        }else{
          ElMessage.error("修改失败")
        }
      })
    }else{
      console.log("没通过")
    }
  })

}
// 获取信息
const getInit = ()=>{
  store.dispatch("loginModel/getAdminInfo").then(res=>{
    if(res.success){
      formData.name = res.data.Name
      formData.userName = res.data.UserName
      formData.tel = res.data.Tel
      formData.mchId = res.data.MchId
      formData.secret = res.data.Secret
      formData.keyFile = res.data.KeyFile
      formData.certFile = res.data.CertFile
      formData.address = res.data.Address
    }
    console.log(res)
  })
}
onMounted(()=>{
  getInit()
})

</script>

<style scoped>
.dialog-item1 {
  width: 100%;
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
