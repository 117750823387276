import request from "@/utils/request";
// 登录
function login(data){
    return request({
        url:'/login',
        data:data,
        method:'post'
    })
}

// 获取当前用户登录信息
function currentUser(data){
    return request({
        url:'/admin_any/currentUser',
        data:data,
        method:'get'
    })
}

// 修改管理员密码
function changeAdminPassword(data){
    return request({
        url:'/admin/changeAdminPassword',
        data:data,
        method:'post'
    })
}

// 查询总管理员信息，即总部信息
function getAdminInfo(data){
    return request({
        url:'/admin/getAdminInfo',
        data:data,
        method:'get'
    })
}
// 修改总管理员信息
function updateAdminInfo(data){
    return request({
        url:'/admin/updateAdminInfo',
        data:data,
        method:'post'
    })
}
export {
    login,
    currentUser,
    changeAdminPassword,
    getAdminInfo,
    updateAdminInfo
}
