

const state = {
    currentTitle: "", // 页面当前顶部标题
    isCollapse:false,// 左侧菜单是打开还是关闭模式
    currentRouter:null

}

const mutations = {
    // 修改标题
    changeTitle: (state, payload) => {
        state.currentTitle = payload
    },
    // 修改开合
    changeCollapse: (state, payload) => {
        state.isCollapse = payload
    },
//    修改当前path
    currentRouter:(state, payload) => {
        state.currentRouter = payload
    },

}
const actions = {


}
const modules = {}
export default {
    namespaced: true,
    state() {
        return state
    },
    mutations,
    actions,
    modules
}

