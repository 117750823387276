import { createRouter, createWebHashHistory  } from 'vue-router'
import Layout from '@/views/Layout'
import store from '@/store'
import {ElMessage} from "element-plus";

/**
 * roler:
 * supermanager(超级管理员，厂商级别)
 * commanager：企业或代理商
 * storemanager:店铺管理员
 * */
const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  //  isAdmin 只有管理员才能看见
  {
    path: '/home',
    name: 'home',
    // redirect: '/agentPage',
    meta: {title: '首页'},
    component: Layout,
    beforeEnter:(to,from,next)=>{
      const menue = getUserMenue()
      console.log('to',to)
      console.log('from',from)
      console.log(menue)
      if(to.path==="/home"){
        next({path:menue[0].path,replace:true})
      }else{
        next()
      }
      // next()

    },
    children:[
      //   超级管理员
      {
        path: '/agent',
        name: 'agentPage',
        meta: {title: '企业管理', keepalive: false, showTitle: true,icon:"Setting",role:"supermanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/supermanager/agentPage'),
      },
      {
        path: '/orderAll',
        name: 'orderAllPage',
        meta: {title: '订单管理', keepalive: false, showTitle: true,icon:"Tickets",role:"supermanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/supermanager/orderAllPage'),
      },
      {
        path: '/deviceAll',
        name: 'deviceAllPage',
        meta: {title: '设备管理', keepalive: false, showTitle: true,icon:"Box",role:"supermanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/supermanager/deviceAllPage'),
      },
      //  企业或代理
      {
        path: '/store',
        name: 'storePage',
        meta: {title: '门店管理', keepalive: false, showTitle: true,icon:"OfficeBuilding",role:"commanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/commanager/storePage'),
      },
      {
        path: '/deviceList',
        name: 'deviceListPage',
        meta: {title: '设备管理', keepalive: false, showTitle: true,icon:"Cpu",role:"commanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/commanager/deviceListPage'),
      },
      // {
      //   path: '/timesCard',
      //   name: 'timesCard',
      //   meta: {title: '门店次卡列表', keepalive: false, showTitle: true,icon:"CreditCard",role:"commanager"},
      //   component:  () => import(/* webpackChunkName: "about" */ '../views/commanager/timesCardPage'),
      // },
      {
        path: '/clockCard',
        name: 'clockCard',
        meta: {title: '门店时卡列表', keepalive: false, showTitle: true,icon:"AlarmClock",role:"commanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/commanager/clockCardPage'),
      },
      {
        path: '/cardOrder',
        name: 'cardOrder',
        meta: {title: '门店开卡订单', keepalive: false, showTitle: true,icon:"List",role:"commanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/commanager/cardOrderPage'),
      },
      {
        path: '/orderList',
        name: 'orderListPage',
        meta: {title: '吸氧订单列表', keepalive: false, showTitle: true,icon:"Tickets",role:"commanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/commanager/orderListPage'),
      },
      //  店铺
      {
        path: '/storeOrderList',
        name: 'storeOrderList',
        meta: {title: '吸氧订单列表', keepalive: false, showTitle: true,icon:"List",role:"storemanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/storemanager/storeOrderListPage'),
      },
      {
        path: '/storeOrderPayback',
        name: 'storeOrderPayback',
        meta: {title: '吸氧退费管理', keepalive: false, showTitle: true,icon:"Coin",role:"storemanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/storemanager/storeOrderPaybackPage'),
      },
      {
        path: '/feeList',
        name: 'feeListPage',
        meta: {title: '吸氧费用管理', keepalive: false, showTitle: true,icon:"Money",role:"storemanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/storemanager/feeListPage'),
      },
      {
        path: '/storeClockCard',
        name: 'storeClockCard',
        meta: {title: '时卡管理', keepalive: false, showTitle: true,icon:"AlarmClock",role:"storemanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/storemanager/storeClockCardPage'),
      },
      // {
      //   path: '/storeTimesCard',
      //   name: 'storeTimesCard',
      //   meta: {title: '次卡管理', keepalive: false, showTitle: true,icon:"CreditCard",role:"storemanager"},
      //   component:  () => import(/* webpackChunkName: "about" */ '../views/storemanager/storeTimesCardPage'),
      // },
      {
        path: '/storeCardOrderList',
        name: 'storeCardOrderList',
        meta: {title: '时卡订单列表', keepalive: false, showTitle: true,icon:"List",role:"storemanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/storemanager/storeClockCardOrderListPage'),
      },
      {
        path: '/storeCardPayback',
        name: 'storeCardPayback',
        meta: {title: '时卡退费管理', keepalive: false, showTitle: true,icon:"SetUp",role:"storemanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/storemanager/storeClockCardPaybackPage'),
      },
      {
        path: '/storeDeviceList',
        name: 'storeDeviceList',
        meta: {title: '设备管理', keepalive: false, showTitle: true,icon:"Box",role:"storemanager"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/storemanager/storeDeviceListPage'),
      },

    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/:path(.*)',
    name: '404',
    meta: {},
    component: () => import(/* webpackChunkName: "about" */ '../views/ErrorPage.vue')
  }
]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// 增加路由守卫
const changeTitle = (to) => {
  if (to.meta && (to.meta.showTitle||to.meta.showTop)) { // 左侧的菜单全部显示，属性为showTop的菜单也全部显示
    store.commit("sysModel/changeTitle", to.meta.title)
  }
}
router.beforeEach((to, from, next) => {
  const isLogin = store.getters['loginModel/getLoginState']
  const userInfo = store.getters['loginModel/getUserInfo']

  if (isLogin) { // 如果已经登录
    // 判断权限是否相符
    if(to.meta.role && (to.meta.role!==userInfo.role)){ // 如果访问的带权限的页面，而且访问的页面不属于当前页面权限
        ElMessage.error('您无权访问当前页面')
      store.commit("loginModel/loginOut")
      // return next({path:'/'})
    }
    // 判断是否登录
    if (to.name === 'login') {
      next({path:'/'}) // 如果已经登录了，跳转到登录页的时候，登录到首页
    } else {
      next()
    }
    changeTitle(to)
    store.commit("sysModel/currentRouter", to.path)
  } else { // 如果没登录，跳转到登录页面，登录页不显示进度条
    // next()
    if (to.name === 'login') {
      next()
    } else {
      next({path: "/login"})
    }

  }
})
// 根据用户信息，获取用户的菜单列表
export function getUserMenue(){
  let menus = [],filterMenue=[];
  let admin = false;
  // 获取菜单列表
  const user = store.getters['loginModel/getUserInfo'] || {}
  admin = user.role
  if(!admin){
      ElMessage.error('无法获取该账户权限')
    store.commit("loginModel/loginOut")
  }
  // 筛选菜单
  const dealRouter = (routes)=>{
    routes.forEach(items=>{
      if(items && items.meta){
        if(items.meta.showTitle){
          menus.push({title:items.meta.title,path:items.path,name:items.name,isAdmin:items.meta.isAdmin,icon:items.meta.icon,role:items.meta.role})
        }
      }
      if(items.children){
        dealRouter(items.children)
      }
    })
  }
    dealRouter(routes)


  // 过滤菜单 根据是否是admin
   filterMenue = menus.filter(v=>(!v.role || v.role===admin))
  // todo menus 改 filterMenue
  store.commit('loginModel/resetMenus',filterMenue)
  return filterMenue
}

export default router
