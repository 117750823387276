// 超级管理员-代理数据

import {addAgent, changeAgentPassword, updateAgentStatus, getAgents, removeAgent, updateAgent} from "@/api/store";
const state = {
  list:[]

}

const mutations = {


}
const actions = {
    // add
    async addAgent(context, params) {
        const res = await addAgent(params)
        return Promise.resolve(res)
    },
    // update
    async updateAgent(context, params) {
        const res = await updateAgent(params)
        return Promise.resolve(res)
    },
    // list
    async getAgents(context, params) {
        const res = await getAgents(params)
        if(res.data && res.data.length>0){
            context.state.list = res.data
        }else{
            context.state.list = []
        }
        return Promise.resolve(res)
    },
    // remove
    async removeAgent(context, params) {
        const res = await removeAgent(params)
        return Promise.resolve(res)
    },
    // changepassword
    async changeAgentPassword(context, params) {
        const res = await changeAgentPassword(params)
        return Promise.resolve(res)
    },
//     changestate
    async updateAgentStatus(context, params) {
        const res = await updateAgentStatus(params)
        return Promise.resolve(res)
    },

}
const modules = {}
export default {
    namespaced: true,
    state() {
        return state
    },
    mutations,
    actions,
    modules
}

