import {
    addCard2,
    adminGetAllCard2s,
    getAgentCard20rders, getAgentRefundCardOrders, getCard20rders,
    getCard2s, handleCardRefundByAgent,
    removeCard2,
    updateCard2,
    updateCard2Status
} from "@/api/cards";


const state = {

}

const mutations = {


}
const actions = {
    // 总部-查询会员卡后台
    async adminGetAllCard2s(context, params) {
        const res = await adminGetAllCard2s(params)
        return Promise.resolve(res)
    },
// 添加会员卡
    async addCard2(context, params) {
        const res = await addCard2(params)
        return Promise.resolve(res)
    },
// 门店-查询会员卡
    async getCard2s(context, params) {
        const res = await getCard2s(params)
        return Promise.resolve(res)
    },
// 门店-删除会员卡
    async removeCard2(context, params) {
        const res = await removeCard2(params)
        return Promise.resolve(res)
    },
// 门店-修改会员卡
    async updateCard2(context, params) {
        const res = await updateCard2(params)
        return Promise.resolve(res)
    },
// 门店-修改会员卡状态
    async updateCard2Status(context, params) {
        const res = await updateCard2Status(params)
        return Promise.resolve(res)
    },
    // 门店-时卡订单查询
    async getAgentCard20rders(context, params) {
        const res = await getAgentCard20rders(params)
        return Promise.resolve(res)
    },
    //  门店-退卡订单列表
    async getAgentRefundCardOrders(context, params) {
        const res = await getAgentRefundCardOrders(params)
        return Promise.resolve(res)
    },
    // 门店-退卡 同意或者拒绝
    async handleCardRefundByAgent(context, params) {
        const res = await handleCardRefundByAgent(params)
        return Promise.resolve(res)
    },
    // 管理端-卡订单列表
    async getCard20rders(context, params) {
        const res = await getCard20rders(params)
        return Promise.resolve(res)
    },


}
const modules = {}
export default {
    namespaced: true,
    state() {
        return state
    },
    mutations,
    actions,
    modules
}
