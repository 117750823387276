import {
    addDevice,
    getAgentDevices,
    getDeviceQrcode,
    getDevices,
    removeDevice,
    updateDevice,
    updateDeviceInfo
} from "@/api/device";

const state = {

}

const mutations = {


}
const actions = {
    // add
    async addDevice(context, params) {
        const res = await addDevice(params)
        return Promise.resolve(res)
    },
    // update
    async updateDevice(context, params) {
        const res = await updateDevice(params)
        return Promise.resolve(res)
    },
    // list
    async getDevices(context, params) {
        const res = await getDevices(params)
        return Promise.resolve(res)
    },
    // remove
    async removeDevice(context, params) {
        const res = await removeDevice(params)
        return Promise.resolve(res)
    },
    // 二维码
    async getDeviceQrcode(context, params) {
        const res = await getDeviceQrcode(params)
        return Promise.resolve(res)
    },
//     门店
    // update
    async updateDeviceInfo(context, params) {
        const res = await updateDeviceInfo(params)
        return Promise.resolve(res)
    },
    // list
    async getAgentDevices(context, params) {
        const res = await getAgentDevices(params)
        return Promise.resolve(res)
    },
}
const modules = {}
export default {
    namespaced: true,
    state() {
        return state
    },
    mutations,
    actions,
    modules
}
