import Cookies from 'js-cookie'

const TokenKey = 'o2share-token'
//  electron 里面没法使用js-cookie 不知道为啥
export function getToken() {
        return Cookies.get(TokenKey)
}

export function setToken(token) {
        let timestamp = new Date().getTime();//当前的时间戳
        timestamp = timestamp + 24 * 60 * 60 * 1000 * 30;
        Cookies.set(TokenKey, token, {expires: new Date(timestamp)})
}

export function removeToken() {
        return Cookies.remove(TokenKey)
}



