<template>
 <div class="menuList">
<!--   <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">-->
<!--     <el-radio-button :label="false">expand</el-radio-button>-->
<!--     <el-radio-button :label="true">collapse</el-radio-button>-->
<!--   </el-radio-group>-->
   <el-menu
       :default-active="currentRouter"
       class="el-menu-vertical-demo"
       :collapse="isCollapse"
       @open="handleOpen"
       @close="handleClose"
   >
     <el-menu-item :index="item.path" v-for="(item,index) in menulist" @click="goPage(item)">
       <el-icon> <component :is="item.icon" style="width: 18px;height: 18px;"></component></el-icon>
       <template #title>{{item.title}}</template>
     </el-menu-item>
   </el-menu>
 </div>
</template>

<script setup>
// import router from '@/router'
import {computed,onMounted,ref} from "vue"
import {useStore} from "vuex";
const store = useStore()
const isCollapse = computed(() => store.state.sysModel.isCollapse)
import { useRouter } from 'vue-router';
const router= useRouter()
const currentRouter = computed(()=>store.state.sysModel.currentRouter)

const handleOpen = (key, keyPath) => {
  // console.log(key, keyPath)
}
const handleClose = (key, keyPath) => {
  // console.log(key, keyPath)
}
const menulist = computed(()=>store.state.loginModel.menulist)
// console.log('menulist',menulist)
// 跳转页面
const goPage = (item)=>{
  const path = item.path
      router.push({
          path: path
      })
}
onMounted(()=>{
  // 跳至第一个页面
  // goPage(menulist.value[0])
})
</script>

<style scoped>
 .menuList{
   width: 100%;
 }
 .el-menu-vertical-demo:not(.el-menu--collapse) {
   width: 200px;
   min-height: 400px;
 }
</style>
