import request from "@/utils/request";
// 门店-订单列表
export function getAgentOxygenOrders(data){
    return request({
        url:'/adminAgent/getAgentOxygenOrders',
        data:data,
        method:'get'
    })
}
// 门店-代理商退款订单列表
export function getAgentRefundOrders(data){
    return request({
        url:'/adminAgent/getAgentRefundOrders',
        data:data,
        method:'get'
    })
}
// 门店-代理商处理退款订单
export function handleRefundByAgent(data){
    return request({
        url:'/adminAgent/handleRefundByAgent',
        data:data,
        method:'post'
    })
}

// 管理员-订单列表
export function getOxygenOrders(data){
    return request({
        url:'/admin/getOxygenOrders',
        data:data,
        method:'get'
    })
}
// 管理员-删除订单
export function removeOxygenOrder(data){
    return request({
        url:'/admin/removeOxygenOrder',
        data:data,
        method:'get'
    })
}
