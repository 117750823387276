/**
 * 封装axios，增加request拦截器，将每次请求的header增加token
 * 增加response拦截器，非正常返回提示错误消息和session过期提示重新登录
 */
import axios from 'axios'
import {getToken} from '@/utils/token'
import {ElMessage} from 'element-plus'
import qs from 'qs'
import store from "@/store" // 此处使用store，不能使用usestore
// 创建axios实例
const $axios = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin-Type':"*",
    },
    responseType: "application/json",
    baseURL: process.env.VUE_APP_BASE_API, // 判断是electron 还是网页版
    timeout: 30 * 1000
})

// request拦截器
$axios.interceptors.request.use(
    config => {
        if(config.method === 'GET' || config.method === 'get'){
            if(config.data){
                const query = qs.stringify(config.data, { indices: false })
                config.url = `${config.url}?${query}`
            }
        }
        // 处理token
        if (getToken()) {
            config.headers.Authorization = `Bearer ${getToken()}`
        } else {
            config.headers['Authorization'] = ""
        }
        // 处理头部
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response拦截器
$axios.interceptors.response.use(
    response => {
        const {data} = response;
        if (data.errorCode === 401) { // token失效
            ElMessage.error('您的账号使用受限')
            store.dispatch('loginModel/loginOut')
            return Promise.reject(data.msg || '请求出错')
        } else {
            // ElMessage.error(data.msg || data.message || '请求出错')
                try {
                    if(data &&  typeof(data)=='string' ){
                        return Promise.resolve(JSON.parse(data))
                    }else if(data){
                        return Promise.resolve(data)
                    }
                }catch (err){
                    return Promise.reject(err)
                }


        }
    },
    error => {
        if(error.request.status===401){
            ElMessage.error(error.message || '您的账号使用受限')
            store.dispatch('loginModel/loginOut')
        }
        return Promise.reject(error)
    }
)




export default $axios
