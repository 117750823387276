
import {addModePackage, getModePackages, removeModePackage, updateModePackage} from "@/api/modePackage";

const state = {
    list:[]
}

const mutations = {


}
const actions = {
    // add
    async addModePackage(context, params) {
        const res = await addModePackage(params)
        return Promise.resolve(res)
    },
    // update
    async updateModePackage(context, params) {
        const res = await updateModePackage(params)
        return Promise.resolve(res)
    },
    // list
    async getModePackages(context, params) {
        const res = await getModePackages(params)
        context.state.list = res.data || []
        return Promise.resolve(res)
    },
    // remove
    async removeModePackage(context, params) {
        const res = await removeModePackage(params)
        return Promise.resolve(res)
    }
}
const modules = {}
export default {
    namespaced: true,
    state() {
        return state
    },
    mutations,
    actions,
    modules
}
