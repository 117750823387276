import {
    getAgentOxygenOrders,
    getAgentRefundOrders,
    getOxygenOrders,
    handleRefundByAgent,
    removeOxygenOrder
} from "@/api/order";

const state = {

}

const mutations = {


}
const actions = {
    // 门店-订单列表
    async getAgentOxygenOrders(context, params) {
        const res = await getAgentOxygenOrders(params)
        return Promise.resolve(res)
    },
    // 门店-代理商退款订单列表
    async getAgentRefundOrders(context, params) {
        const res = await getAgentRefundOrders(params)
        return Promise.resolve(res)
    },
    // 门店-代理商处理退款订单
    async handleRefundByAgent(context, params) {
        const res = await handleRefundByAgent(params)
        return Promise.resolve(res)
    },
    // 管理员-订单列表
    async getOxygenOrders(context, params) {
        const res = await getOxygenOrders(params)
        return Promise.resolve(res)
    },
// 管理员-删除订单
    async removeOxygenOrder(context, params) {
        const res = await removeOxygenOrder(params)
        return Promise.resolve(res)
    }
}
const modules = {}
export default {
    namespaced: true,
    state() {
        return state
    },
    mutations,
    actions,
    modules
}
