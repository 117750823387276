import request from "@/utils/request";
// 设备相关的接口
// 添加
export function addModePackage(data){
    return request({
        url:'/adminAgent/addModePackage',
        data:data,
        method:'post'
    })
}
// 修改
export function updateModePackage(data){
    return request({
        url:'/adminAgent/updateModePackage',
        data:data,
        method:'post'
    })
}
// 获取列表
export function getModePackages(data){
    return request({
        url:'/adminAgent/getModePackages',
        data:data,
        method:'get'
    })
}
// 删除
export function removeModePackage(data){
    return request({
        url:'/adminAgent/removeModePackage',
        data:data,
        method:'post'
    })
}


