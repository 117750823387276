<template>
  <div style="width: 100%;">
    <el-upload
        :file-list="fileList"
        ref="uploadRef"
        class="upload-demo"
        :on-change="toUploadFile"
        :limit="limit"
        :on-exceed="handleExceed"
        action="#"
        :auto-upload="false"
        :show-file-list = false
    >
      <template #trigger>
          <el-button type="primary"  plain :icon="Upload" v-if="!loading">点击上传</el-button>
          <el-button type="primary"  plain :icon="Upload" disabled  loading v-if="loading">正在上传</el-button>
      </template>
    </el-upload>
  </div>

  <div class="filecontent"  v-for="(item,index) of fileList" :key="index">
    <div class="name">{{item.name}}</div>
    <div class="icon success">
      <Check class=" icon-item"  />
    </div>
    <div class="icon delete" @click="handleRemove(index)">
      <Delete class=" icon-item"  />
    </div>
  </div>
</template>

<script setup>
import {Check,Delete,Upload} from '@element-plus/icons-vue'
import {ref, defineProps, defineEmits, watch,reactive,computed} from 'vue';
import {ElMessage,genFileId } from "element-plus";
import {uploadCertFile} from "@/api/commonApi";
const emits = defineEmits([ 'changeData'])
const uploadRef = ref()
const props = defineProps({
  list: {
    default: null,
  },
  limit: {
    default: 2,
  }
})
const loading = ref(false)

const fileList = ref([])

watch(() => props.list, (newVal, oldVal) => {
  console.log(newVal,oldVal)
 if(newVal && typeof (newVal) === 'string'){
   fileList.value =  [{name:newVal,url:newVal}]

 }
},{immediate:true,//首次加载的时候执行函数
  deep:true,})
// 文件上传
const toUploadFile = async (rawFile,flag)=>{
  if(!rawFile.raw)return
  const nS = rawFile.name?rawFile.name.split(".") || []:[]
  if(nS.length<=0 || nS[nS.length-1] !== "pem"){
    return ElMessage.warning('请上传以pem结尾的文件')
  }
  if(rawFile.size<=0){
    return ElMessage.warning('您上传的文件为空')
  }
  loading.value = true
  let format = new FormData()
  format.append('file',rawFile.raw)
  format.append('name',rawFile.name)
  const res = await uploadCertFile(format)
  loading.value = false
  if(res.err_msg ==="OK"){
    ElMessage.success("上传成功")
    rawFile.uploadUrl = res.data
    fileList.value = [{name:res.data,url:res.data}]
    emits("changeData",res.data)
    return true
  }else{
    ElMessage.error("上传失败")
    return false
  }

}
const handleRemove = ()=>{
  fileList.value = []
  emits("changeData",null)
}
const handleExceed = (files,flag)=>{
  if(uploadRef.value){
    uploadRef.value.clearFiles()
    const file = files[0]
    file.uid = genFileId()
    uploadRef.value.handleStart(file)
  }
}
</script>

<style scoped lang="scss">
.filecontent{
  width: 100%;
  display: flex;
  align-items: center;
  &:hover{
    background: rgb(0, 89, 255,0.1);
    .delete{
      display: block;
    }
    .success{
      display: none;
    }
  }
}
.name{
  width: calc(100% - 30px);
}
.icon{
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  .icon-item{
    width: 1em;
    height: 1em;
  }

}
.success{
  display: block;
  .icon-item{
    color: #00ff33;

  }
}
.delete{
  display: none;
  .icon-item{
    color: red;
  }
  cursor: pointer;
}
</style>
