import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "bootstrap/dist/css/bootstrap.min.css"

// import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@/assets/css/index.scss'
// import 'bootstrap-icons/font/bootstrap-icons.css';

const app = createApp(App)
// 汉化
import locale from 'element-plus/lib/locale/lang/zh-cn'
app.use(ElementPlus,{locale})
app.use(store).use(router).use(ElementPlus).mount('#app')
// 注册全局字体
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


