import request from "@/utils/request";
// 设备相关的接口
// 添加-管理员
export function addDevice(data){
    return request({
        url:'/admin/addDevice',
        data:data,
        method:'post'
    })
}
// 修改-管理员
export function updateDevice(data){
    return request({
        url:'/admin/updateDevice',
        data:data,
        method:'post'
    })
}
// 获取列表-管理员
export function getDevices(data){
    return request({
        url:'/admin/getDevices',
        data:data,
        method:'get'
    })
}
// 删除-管理员
export function removeDevice(data){
    return request({
        url:'/admin/removeDevice',
        data:data,
        method:'post'
    })
}
// 门店操作
// 修改-管理员
export function updateDeviceInfo(data){
    return request({
        url:'/adminAgent/updateDeviceInfo',
        data:data,
        method:'post'
    })
}
// 获取列表-管理员
export function getAgentDevices(data){
    return request({
        url:'/adminAgent/getAgentDevices',
        data:data,
        method:'get'
    })
}



// 获取小程序二维码
export function getDeviceQrcode(data){
    return request({
        url:'/getDeviceQrcode',
        data:data,
        method:'get'
    })
}
