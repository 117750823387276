import { createStore } from 'vuex'
import loginModel from "./login"
import sysModel from "./sys"
import storeModel from "./store"
import orderAllModel from "@/store/orderAll";
import deviceModel from "@/store/device"
import modepackgeModel from "@/store/modepackage"
import orderModel from "@/store/order"
import cardModel from "@/store/cards"
// 本地化存储
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    loginModel: loginModel,
    sysModel:sysModel,
    storeModel:storeModel,
    orderAllModel:orderAllModel,
    deviceModel:deviceModel,
    modepackgeModel:modepackgeModel,
    orderModel:orderModel,
    cardModel:cardModel,
  },
  // 设置本地化存储
  plugins: [
    // 默认储存在localstorage
    createPersistedState({
      // 本地储存名
      key: 'projectShareWYYC',
      // 指定数据进行本地存储
      paths: ['loginModel.login','loginModel.userInfo','loginModel.auth',]
    })
  ],
})
