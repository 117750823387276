import {ElMessage} from 'element-plus'
import {removeToken, setToken} from "@/utils/token"
import {login, changeAdminPassword, getAdminInfo, updateAdminInfo, currentUser} from "@/api/login";
// 处理路由

// roler:supermanager(超级管理员，暂时没做)/commanager（管理员admin）/storemanager（门店agent）
const state = {
    login: false, // 是否登录
    userInfo: {role: null,name:null,access:null},//用户信息 access:admin/agent
    auth: null,// 权限相关信息,
    menulist: [],// 菜单列表 分为管理员菜单和非管理员菜单
}
const getters = {
    getLoginState: (state) => state.login,// 获取登录状态
    getUserInfo: (state) => state.userInfo,// 获取登录状态
}
const mutations = {
    // 登录成功
    login: (state, payload) => {
        state.login = payload
    },
    userInfo: (state, payload) => {
        state.userInfo = {...state.userInfo, ...payload}
    },
    loginOut: (state, payload) => {
        // state.login = payload === 'undefined' ? false : !payload
        state.login = false // 登出
        removeToken() // 消除token
        state.menulist = [] // 清空菜单
        window.location.reload()
    },
    // 处理路由
    resetMenus: (state, payload) => {
        state.menulist = payload ? payload : []
    }
}
const actions = {
    loginOut(context) { // 退出
        context.commit("loginOut", true)
        context.commit("userInfo", {})
    },
    async login(context, data) {//登录 todo
        const dist = {"admin":"commanager","agent":"storemanager"} // 做一个角色的转换
        const res = await login(data)
        const status = res.status
        const role = dist[res.role]
        if (status == 'ok' && role) {
            // 设置登录状态
            context.commit("login", true)
            // 设置用户信息
            context.commit("userInfo", {role: role})
            // 设置token
            setToken(res.token)
            //设置路由
            window.location.reload()
            return Promise.resolve(true)
        }else{
            ElMessage.error('账号或密码错误')
            return Promise.resolve(false)
        }
    },
    // 修改管理员密码
    async changeAdminPassword(context,data){
        const res = await changeAdminPassword(data)
        return Promise.resolve(res)
    },
    // 查询总管理员信息，即总部信息
    async getAdminInfo(context,data){
        const res = await getAdminInfo(data)
        return Promise.resolve(res)
    },
    // 修改总管理员信息
    async updateAdminInfo(context,data){
        const res = await updateAdminInfo(data)
        return Promise.resolve(res)
    },
//    获取账户信息
    async currentUser(context,data){
        const res = await currentUser(data)
        if(res){
            context.commit("userInfo", res)
        }
        return Promise.resolve(res)
    }

}
const modules = {}
export default {
    namespaced: true,
    state() {
        return state
    },
    getters,
    mutations,
    actions,
    modules
}

