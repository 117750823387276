// 超级管理员-代理数据
import * as Api from "@/api/agent";
const state = {
    agentList:[],// 代理商


}

const mutations = {


}
const actions = {


}
const modules = {}
export default {
    namespaced: true,
    state() {
        return state
    },
    mutations,
    actions,
    modules
}

