import request from "@/utils/request";
//图片上传
function uploadImage(data){
    return request({
        url:'/upload-image',
        data:data,
        method:'post',
        headers: { "Content-Type": "multipart/form-data" }
    })
}
//文件上传
function uploadCertFile(data){
    return request({
        url:'/uploadCertFile',
        data:data,
        method:'post',
        headers: { "Content-Type": "multipart/form-data" } // 必须设置表头，不然上传失败
    })
}


export {
    uploadImage,
    uploadCertFile
}
