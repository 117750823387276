<template>
  <div class="i-container">
    <!--   头部-->
    <div class="i-header flex">
      <!--      左边-->
      <div class="h-left h-left-min">
        <div class="isCollapseIcon">
          <img style="margin-left: 5px" src="../assets/images/sys/logo-mini.png" width="40" height="40"/>
        </div>
      </div>
      <div class="h-left h-left-large" >
        <img style="margin-left: 5px" v-if="!isCollapse" src="../assets/images/sys/logo.png" width="120" height="30"/>
        <div class="isCollapseIcon" @click="openMenu()">
          <el-icon  size="25" v-if="!isCollapse"><Fold /> </el-icon>
          <el-icon  size="25" v-if="isCollapse"> <Expand /></el-icon>
        </div>

      </div>
      <div class="h-center">
        {{currentTitle}}
      </div>
      <!--     右侧-->
      <div class="h-right">
        <el-dropdown>
    <span class="el-dropdown-link userinfo-box">
      <img class="dropdown-toggle userIcon" src="../assets/images/sys/o2sharelogo.png"/>
           <span class="username">{{userInfo.name?userInfo.name:userInfo.role==='commanager'?'企业超级管理员':'店铺管理员'}}</span>
           <el-icon><ArrowDown size="14" /></el-icon>
    </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="userInfo.role==='commanager'" :icon="EditPen" @click="showUserInfo">修改信息</el-dropdown-item>
              <el-dropdown-item :icon="EditPen" v-if="userInfo.role==='commanager'" @click="changePassword">修改密码</el-dropdown-item>
              <el-dropdown-item :icon="TurnOff" @click="loginOut">退出系统</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <img @click="toggleDraw" class="collapse-menu" src="../assets/images/sys/menu.png" width="40" height="40"/>
      </div>

    </div>
    <div class="i-content flex">
      <div :class="isCollapse?'c-left isCollapse-menu':'c-left'">
        <MenuListCom></MenuListCom>
      </div>
      <div class="c-right">
        <router-view v-slot="{ Component }" >
          <keep-alive>
            <component :is="Component" :key="$route.name"  v-if="$route.meta.keepalive"/>
          </keep-alive>
          <component :is="Component" :key="$route.name"  v-if="!$route.meta.keepalive"/>
        </router-view>
      </div>
    </div>

  </div>
<!--   抽屉-->
<!--  <el-drawer v-model="drawer" title="I am the title" :with-header="false">-->
<!--    <span>Hi there!</span>-->
<!--  </el-drawer>-->
  <el-drawer v-model="drawer" direction="ltr" size="230" @click="toggleDraw">
    <MenuListCom></MenuListCom>
  </el-drawer>
<!--  个人信息-->
  <agentEdit  v-if="dialogVisible" @close="closeInfoChange"
             @success="updateInfoChange"></agentEdit>
<!--  -->
  <myPasswordEdit v-if="showMyPasswordEdit" @close="closeMyPasswordEdit" @success="loginOut"></myPasswordEdit>
</template>

<script setup>
import agentEdit from "@/components/agentEdit"
import myPasswordEdit from "@/components/myPasswordEdit"
import MenuListCom from "@/components/MenuListCom.vue"
import {ArrowDown, TurnOff,Fold,Expand,EditPen} from "@element-plus/icons-vue"
import {useStore} from 'vuex'
import {computed,ref,onMounted} from "vue"
import {ElMessage} from "element-plus"; // 引入useStore 方法
let drawer = ref(false)
const store = useStore()
const currentTitle = computed(() => store.state.sysModel.currentTitle)
const isCollapse = computed(() => store.state.sysModel.isCollapse)
const userInfo = computed(() => store.state.loginModel.userInfo)
const dialogVisible = ref(false)
// 修改密码
const showMyPasswordEdit = ref(false)
const changePassword = ()=>{
  showMyPasswordEdit.value = true
}
const closeMyPasswordEdit = ()=>{
  showMyPasswordEdit.value = false
}

// 登出
const loginOut = () => {
  store.dispatch('loginModel/loginOut').then(res => {
    ElMessage.success('登出成功')
  })
}
// 打开或关闭菜单
const openMenu = ()=>{
  store.commit("sysModel/changeCollapse",!isCollapse.value)

}
// 顶部菜单
const toggleDraw = ()=>{
  store.commit("sysModel/changeCollapse",false)
  drawer.value = !drawer.value
}
// 编辑个人信息
const showUserInfo = ()=>{
  if(userInfo.value.role!=='commanager'){
    return
  }
  dialogVisible.value = true
}
// 个人信息
const updateInfoChange = ()=>{
  dialogVisible.value = false
}
const closeInfoChange = ()=>{
  dialogVisible.value = false
}
onMounted(()=>{
  // 获取用户信息
  store.dispatch("loginModel/currentUser")
})

</script>

<style scoped lang="scss">
$header: 60px;
$padding: 5px;
$boderC:#dcdfe6;

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.collapse-menu {
  display: none;
  margin-left: 10px;

}

.userinfo-box {
  display: flex;
  align-items: center;

  .userIcon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .username {
    margin-right: 5px;
  }
}


.i-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .i-header {
    height: $header;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items:center;
    just-content:center;


    .h-left {
      //padding: 0 $padding;
      width: 200px;
      flex: 0 0 auto;
      height: 100%;
      line-height: 100%;
      align-items: center;
      //justify-content: center;

    }

    .h-left-large {
      //width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //-webkit-transition: width 2s;
      //-moz-transition: width 2s;
      //-o-transition: width 2s;
      transition: width 4s;

    }

    .h-left-min {
      width: 120px;
    }
    .h-center{
      flex: 1;
      text-align: center;
      color: #3f9dfe;
      font-size: 20px;
      font-weight: 600;
    }
    .h-right {
      padding: $padding;
      //background: yellow;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  .i-content {
    padding: $padding 0;
    overflow: hidden;
    height: calc(100vh - $header);
    background: #e4e5e4;

    .c-left {
      width: 200px;
      background: #ffffff;
      flex: 0 0 auto;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      border-right: 1px solid $boderC;
    }
    .isCollapse-menu {
      width: 64px !important;
    }

    .c-right {
      flex: 1;
      background: white;
      padding: $padding;
    }
  }
}

@media (min-width: 1200px) {

  .h-left-min {
    display: none;
  }
  .h-left-large {
    display: flex;
  }
  .h-right{
    width: 200px;
  }
  .c-left {
    display: block;
  }
  .collapse-menu {
    display: none;
  }
  .username {
    display: block;
  }
}

@media (max-width: 1200px) and (min-width: 750px) {
  .h-left-min {
    display: none;
  }
  .h-left-large {
    display: flex;
  }
  .h-right{
    width: 200px;
  }
  .c-left {
    display: block;
  }
  .collapse-menu {
    display: none;
  }
  .username {
    display: block;
  }
}

@media (max-width: 750px) {
  .h-left-min {
    display: flex;
  }
  .h-left-large {
    display: none !important;
  }
  .h-right{
    width: 120px;

  }
  .c-left {
    display: none;
  }
  .collapse-menu {
    display: block;
  }
  .username {
    display: none;
  }
}

.isCollapseIcon{
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e3e3e3;
  height: 100%;
}
.demo-drawer__content{
  background: red;
}
</style>
