import request from "@/utils/request";
// 接口代理商 对应 网页的商铺
// 添加
export function addAgent(data){
    return request({
        url:'/admin/addAgent',
        data:data,
        method:'post'
    })
}
// 修改
export function updateAgent(data){
    return request({
        url:'/admin/updateAgent',
        data:data,
        method:'post'
    })
}
// 获取列表
export function getAgents(data){
    return request({
        url:'/admin/getAgents',
        data:data,
        method:'get'
    })
}
// 删除
export function removeAgent(data){
    return request({
        url:'/admin/removeAgent',
        data:data,
        method:'post'
    })
}
// 修改密码
export function changeAgentPassword(data){
    return request({
        url:'/admin_any/changeAgentPassword',
        data:data,
        method:'post'
    })
}
// 修改状态
export function updateAgentStatus(data){
    return request({
        url:'/admin/updateAgentStatus',
        data:data,
        method:'post'
    })
}
