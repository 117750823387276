import request from "@/utils/request";

// 总部-查询会员卡后台
export function adminGetAllCard2s(data){
    return request({
        url:'/admin//getAllCard2s',
        data:data,
        method:'get'
    })
}
// 添加会员卡
export function addCard2(data){
    return request({
        url:'/adminAgent/addCard2',
        data:data,
        method:'post'
    })
}
// 门店-查询会员卡
export function getCard2s(data){
    return request({
        url:'/adminAgent/getCard2s',
        data:data,
        method:'get'
    })
}
// 门店-删除会员卡
export function removeCard2(data){
    return request({
        url:'/adminAgent/removeCard2',
        data:data,
        method:'post'
    })
}
// 门店-修改会员卡
export function updateCard2(data){
    return request({
        url:'/adminAgent/updateCard2',
        data:data,
        method:'post'
    })
}
// 门店-修改会员卡状态
export function updateCard2Status(data){
    return request({
        url:'/adminAgent/updateCard2Status',
        data:data,
        method:'post'
    })
}
// 门店-时卡订单列表
export function getAgentCard20rders(data){
    return request({
        url:'/adminAgent/getAgentCard2Orders',
        data:data,
        method:'get'
    })
}
// 门店-退卡订单列表
export function getAgentRefundCardOrders(data){
    return request({
        url:'/adminAgent/getAgentRefundCardOrders',
        data:data,
        method:'get'
    })
}

// 门店-退卡 同意或者拒绝
export function handleCardRefundByAgent(data){
    return request({
        url:'/adminAgent/handleCardRefundByAgent',
        data:data,
        method:'post'
    })
}
// 管理端-卡订单列表
export function getCard20rders(data){
    return request({
        url:'/admin/getCard2Orders',
        data:data,
        method:'get'
    })
}

